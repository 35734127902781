import * as React from "react";
import { useNoTaxSwapContract, useTombContract } from "./useContract";
import { BigNumber } from "ethers";
import { rpcUrl } from "../constants";
import { useWeb3React } from "./useWeb3React";
import { JsonRpcProvider } from "@ethersproject/providers";
import { TypedEventFilter, TypedListener } from "../typechain/commons";

export interface INoTombTaxState {
  depositAddress: string;
  volume?: BigNumber;
  swapTxs: string[];
  tombBal?: BigNumber;
  notification: string;
  notificationTitle: string;
  pendingOpen: boolean;
  successfulTx: string;
  depositedBal?: BigNumber;
}

export const noTombTaxStateDefault: INoTombTaxState = {
  depositAddress: "",
  swapTxs: [],
  notification: "",
  notificationTitle: "",
  pendingOpen: false,
  successfulTx: "",
};

// add 10%
function calculateGasMargin(value: BigNumber): BigNumber {
  return value
    .mul(BigNumber.from(10000).add(BigNumber.from(1000)))
    .div(BigNumber.from(10000));
}

export function useNoTaxSwapState() {
  const [state, setState] = React.useReducer<
    (s: INoTombTaxState, n: Partial<INoTombTaxState>) => INoTombTaxState
  >((s, n) => ({ ...s, ...n }), { ...noTombTaxStateDefault });
  const { library, account } = useWeb3React();
  const fallbackProvider = React.useMemo(() => new JsonRpcProvider(rpcUrl), []);

  const signer = React.useMemo(() => library?.getSigner(), [library]);

  const noTaxSwapContract = useNoTaxSwapContract(signer ?? fallbackProvider);
  const tombContract = useTombContract(signer ?? fallbackProvider);

  const swappedFilter = React.useMemo(
    () => noTaxSwapContract.filters.NoTaxSwapped(),
    [noTaxSwapContract]
  );

  React.useEffect(() => {
    noTaxSwapContract.volume().then((volume) => setState({ volume }));

    (library ?? fallbackProvider).getBlockNumber().then(async (block) => {
      const fromBlock = block - 100000;
      const swaps = await noTaxSwapContract.queryFilter(
        swappedFilter,
        fromBlock
      );
      return setState({ swapTxs: swaps.map((s) => s.transactionHash) });
    });
  }, [fallbackProvider, library, noTaxSwapContract, swappedFilter]);

  React.useEffect(() => {
    if (account) {
      noTaxSwapContract.depositAddressFor(account).then((depositAddress) => {
        setState({ depositAddress });
        tombContract
          .balanceOf(depositAddress)
          .then((bal) => setState({ depositedBal: bal }));
      });

      tombContract.balanceOf(account).then((bal) => setState({ tombBal: bal }));
    } else {
      setState({ depositAddress: "", tombBal: undefined });
    }
  }, [noTaxSwapContract, account, tombContract]);

  React.useEffect(() => {
    const handler: TypedListener<[BigNumber], { amount: BigNumber }> = (
      _,
      swapped
    ) => {
      setState({
        volume: (state.volume ?? BigNumber.from(0)).add(swapped.args.amount),
        swapTxs: [...state.swapTxs, swapped.transactionHash],
      });
    };

    noTaxSwapContract.on(swappedFilter, handler);

    return () => {
      noTaxSwapContract.off(swappedFilter, handler);
    };
  }, [noTaxSwapContract, state.swapTxs, state.volume, swappedFilter]);

  React.useEffect(() => {
    if (account) {
      const fromFilter = tombContract.filters.Transfer(account);
      const toFilter = tombContract.filters.Transfer(null, account);

      const balHandler: TypedListener<
        [string, string, BigNumber],
        { from: string; to: string; value: BigNumber }
      > = () => {
        tombContract
          .balanceOf(account)
          .then((bal) => setState({ tombBal: bal }));
      };

      let fromDepositFilter:
        | TypedEventFilter<
            [string, string, BigNumber],
            {
              from: string;
              to: string;
              value: BigNumber;
            }
          >
        | undefined;

      let toDepositFilter:
        | TypedEventFilter<
            [string, string, BigNumber],
            {
              from: string;
              to: string;
              value: BigNumber;
            }
          >
        | undefined;

      if (state.depositAddress) {
        fromDepositFilter = tombContract.filters.Transfer(state.depositAddress);
        toDepositFilter = tombContract.filters.Transfer(
          null,
          state.depositAddress
        );
      }

      let depositHandler:
        | TypedListener<
            [string, string, BigNumber],
            { from: string; to: string; value: BigNumber }
          >
        | undefined;

      if (state.depositAddress) {
        depositHandler = () => {
          tombContract
            .balanceOf(state.depositAddress)
            .then((bal) => setState({ depositedBal: bal }));
        };

        tombContract.on(fromDepositFilter!, depositHandler);
        tombContract.on(toDepositFilter!, depositHandler);
      }

      tombContract.on(fromFilter, balHandler);
      tombContract.on(toFilter, balHandler);

      return () => {
        tombContract.off(fromFilter, balHandler);
        tombContract.off(toFilter, balHandler);

        if (depositHandler !== undefined) {
          tombContract.off(fromDepositFilter!, depositHandler);
          tombContract.off(toDepositFilter!, depositHandler);
        }
      };
    }
  }, [account, state.depositAddress, tombContract]);

  const handleTransactionError = React.useCallback((e: any) => {
    console.error("Failed to deposit", e);
    setState({
      notificationTitle: "Transaction Failed",
    });
    if (e.code === 4001) {
      setState({
        notification: "Transaction Rejected",
      });
    } else {
      if (e?.data?.message) {
        setState({
          notification: e.data.message.replace("execution reverted: ", ""),
        });
      } else {
        setState({
          notification: "Unknown Error",
        });
      }
    }
  }, []);

  const withdraw = React.useCallback(
    async (amount: BigNumber) => {
      if (account) {
        setState({
          pendingOpen: true,
        });
        try {
          const estimatedGas = await noTaxSwapContract.estimateGas.withdraw(
            account,
            amount
          );

          const receipt = await noTaxSwapContract.withdraw(account, amount, {
            gasLimit: calculateGasMargin(estimatedGas),
          });

          setState({
            successfulTx: receipt.hash,
            tombBal: state.tombBal?.add(amount),
            depositedBal: state.depositedBal?.sub(amount),
          });
        } catch (e) {
          handleTransactionError(e);
        } finally {
          setState({
            pendingOpen: false,
          });
        }
      }
    },
    [
      account,
      handleTransactionError,
      noTaxSwapContract,
      state.depositedBal,
      state.tombBal,
    ]
  );

  const deposit = React.useCallback(
    async (amount: BigNumber) => {
      setState({
        pendingOpen: true,
      });
      try {
        const estimatedGas = await tombContract.estimateGas.transfer(
          state.depositAddress,
          amount
        );

        const receipt = await tombContract.transfer(
          state.depositAddress,
          amount,
          {
            gasLimit: calculateGasMargin(estimatedGas),
          }
        );

        setState({
          successfulTx: receipt.hash,
          tombBal: state.tombBal?.sub(amount),
          depositedBal: state.depositedBal?.add(amount),
        });
      } catch (e) {
        handleTransactionError(e);
      } finally {
        setState({
          pendingOpen: false,
        });
      }
    },
    [
      handleTransactionError,
      state.depositAddress,
      state.depositedBal,
      state.tombBal,
      tombContract,
    ]
  );

  const swap = React.useCallback(
    async (amount: BigNumber, weth: boolean, to: string, slippage: number) => {
      setState({
        pendingOpen: true,
      });
      try {
        const minOut = amount.mul((slippage / 100) * 1000).div(1000);

        const estimatedGas = await noTaxSwapContract.estimateGas[
          weth ? "swap" : "swapETH"
        ](to, amount, amount.sub(minOut));

        const receipt = await noTaxSwapContract[weth ? "swap" : "swapETH"](
          to,
          amount,
          amount.sub(minOut),
          { gasLimit: calculateGasMargin(estimatedGas) }
        );

        setState({
          successfulTx: receipt.hash,
          depositedBal: state.depositedBal?.sub(amount),
        });
      } catch (e) {
        handleTransactionError(e);
      } finally {
        setState({
          pendingOpen: false,
        });
      }
    },
    [handleTransactionError, noTaxSwapContract, state.depositedBal]
  );

  const closeNotification = React.useCallback(() => {
    setState({ notification: "" });
  }, []);

  const closePending = React.useCallback(() => {
    setState({ pendingOpen: false });
  }, []);

  const closeSuccessfulTx = React.useCallback(() => {
    setState({ successfulTx: "" });
  }, []);

  return {
    state,
    deposit,
    closeNotification,
    closePending,
    closeSuccessfulTx,
    swap,
    withdraw,
  };
}
