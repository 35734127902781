import * as React from "react";
import { IContentProps } from "./Modal";
import { utils } from "ethers";
import { useNoTaxSwapContext } from "../../Contexts/NoTaxSwapContext";
import styled from "styled-components";
import { pr } from "../../AppTheme";

const Container = styled.div`
  min-width: 25vw;

  div.title {
    background-color: black;
    padding: ${pr(16)} ${pr(32)};
    margin: ${pr(-16)} ${pr(-32)} 0;
    border-top-left-radius: ${pr(5)};
    border-top-right-radius: ${pr(5)};

    h3 {
      margin: 0;
    }
  }

  div.deposit {
    padding: ${pr(16)} 0 0;
  }

  div.bal {
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    padding-bottom: ${pr(6)};
    cursor: pointer;
  }

  div.actions {
    display: flex;
    align-items: center;

    input {
      font-size: ${pr(21)};
      color: black;
      box-sizing: border-box;
      width: 100%;
    }

    button {
      margin: 0 0 0 ${pr(16)};
    }
  }

  div.close-btn {
    width: 100%;
    display: flex;

    button {
      width: 100%;
      margin: ${pr(32)} ${pr(46)} 0;
    }
  }
`;

export const DepositModal: React.FC<IContentProps & { isWithdraw?: boolean }> =
  ({ onClose: handleClose, isWithdraw }) => {
    const { deposit, state, withdraw } = useNoTaxSwapContext();
    const { tombBal, depositedBal } = state;
    const [amount, setAmount] = React.useState("");
    const [pending, setPending] = React.useState(false);

    const handleDeposit = React.useCallback(async () => {
      setPending(true);
      if (isWithdraw) {
        await withdraw(utils.parseEther(amount));
      } else {
        await deposit(utils.parseEther(amount));
      }
      setPending(false);
    }, [amount, deposit, isWithdraw, withdraw]);

    const handleChange = React.useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!e.target.value) {
          setAmount("");
        } else {
          try {
            utils.parseEther(e.target.value);
            setAmount(e.target.value);
          } catch {}
        }
      },
      []
    );

    const handleMax = React.useCallback(() => {
      if (isWithdraw) {
        if (depositedBal) {
          setAmount(utils.formatEther(depositedBal));
        }
      } else {
        if (tombBal) {
          setAmount(utils.formatEther(tombBal));
        }
      }
    }, [depositedBal, isWithdraw, tombBal]);

    return (
      <Container>
        <div className="title">
          <h3>{isWithdraw ? "Withdraw" : "Deposit"}</h3>
        </div>
        <div className="deposit">
          <div className="bal" onClick={handleMax}>
            <div>TOMB {isWithdraw ? "Deposited" : "Balance"}: </div>
            <div>
              {isWithdraw
                ? depositedBal
                  ? utils.formatEther(depositedBal)
                  : "-"
                : tombBal
                ? utils.formatEther(tombBal)
                : "-"}
            </div>
          </div>
          <div className="actions">
            <input min={0} value={amount} onChange={handleChange} />
            <button
              onClick={handleDeposit}
              disabled={
                !amount ||
                utils.parseEther(amount).lte(0) ||
                pending ||
                (isWithdraw
                  ? depositedBal && utils.parseEther(amount).gt(depositedBal)
                  : tombBal && utils.parseEther(amount).gt(tombBal))
              }
            >
              {pending ? "Pending" : isWithdraw ? "Withdraw" : "Deposit"}
            </button>
          </div>
          <div className="close-btn">
            <button onClick={handleClose}>Close</button>
          </div>
        </div>
      </Container>
    );
  };
