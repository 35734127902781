import { Provider } from "@ethersproject/providers";
import { Contract, ContractInterface, Signer } from "ethers/lib/ethers";
import * as React from "react";
import { noTaxSwapAddress, tombAddress } from "../constants";
import noTaxSwap from "../Abis/NoTaxSwap.json";
import tomb from "../Abis/TOMB.json";
import { IERC20, NoTaxSwap } from "../typechain";

function getContract(
  abi: ContractInterface,
  address: string,
  signerOrProvider: Signer | Provider
) {
  return new Contract(address, abi, signerOrProvider);
}

export function useNoTaxSwapContract(signerOrProvider: Signer | Provider) {
  return React.useMemo(
    () =>
      getContract(noTaxSwap, noTaxSwapAddress, signerOrProvider) as NoTaxSwap,
    [signerOrProvider]
  );
}

export function useTombContract(signerOrProvider: Signer | Provider) {
  return React.useMemo(
    () => getContract(tomb, tombAddress, signerOrProvider) as IERC20,
    [signerOrProvider]
  );
}
