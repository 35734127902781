import * as React from "react";
import { IContentProps } from "./Modal";
import styled from "styled-components";
import { pr } from "../../AppTheme";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  h3,
  p {
    color: black;
    text-align: center;
  }

  a {
    display: block;
    text-align: center;
  }

  div.btn {
    padding-top: ${pr(32)};
  }
`;

export const TransactionSuccess: React.FC<
  IContentProps & { para: string; tx: string }
> = ({ onClose: handleClose, para, tx }) => {
  return (
    <Container>
      <div className="text">
        <h3>Transaction Successful</h3>
        <p>{para}</p>
        {
          <a
            href={`https://ftmscan.com/tx/${tx}`}
            target="_blank"
            rel="noreferrer"
          >
            View on FTMScan
          </a>
        }
      </div>
      <div className="btn">
        <button onClick={handleClose}>Close</button>
      </div>
    </Container>
  );
};
