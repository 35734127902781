import * as React from "react";
import { ThemeProvider } from "styled-components";
import { DefaultTheme } from "./AppTheme";
import { history } from "./history";
import { Router } from "react-router-dom";
import { AppRoutes } from "./AppRoutes";
import { Web3ReactProvider } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";
import { getInjected, InjectedContext } from "./Contexts/InjectedContext";
import { NoTaxSwapProvider } from "./Contexts/NoTaxSwapProvider";

function getLibrary(provider: any): Web3Provider {
  const library = new Web3Provider(provider);
  library.pollingInterval = 1000;
  return library;
}

export const App: React.FC = () => {
  const [injected] = React.useState(() => getInjected());

  return (
    <ThemeProvider theme={DefaultTheme}>
      <Web3ReactProvider getLibrary={getLibrary}>
        <InjectedContext.Provider value={injected}>
          <Router history={history}>
            <NoTaxSwapProvider>
              <AppRoutes />
            </NoTaxSwapProvider>
          </Router>
        </InjectedContext.Provider>
      </Web3ReactProvider>
    </ThemeProvider>
  );
};
