import * as React from "react";
import { useNoTaxSwapState } from "../Hooks/useNoTaxSwapState";
import { NoTaxSwapContext } from "./NoTaxSwapContext";

export const NoTaxSwapProvider: React.FC = ({ children }) => {
  const state = useNoTaxSwapState();

  return (
    <NoTaxSwapContext.Provider value={state}>
      {children}
    </NoTaxSwapContext.Provider>
  );
};
