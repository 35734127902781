import * as React from "react";
import styled from "styled-components";
import { pr } from "../../AppTheme";

const Container = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;

  button {
    background-color: ${({ theme }) => theme.palette.purple.dark};
    opacity: 0.5;
    /* border-radius: 1000000000px; */
    border: none;
    box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.6);
    padding: ${pr(2)} ${pr(16)} ${pr(4)};
    font-size: ${pr(18)};
    font-weight: bold;
    color: #999;

    :not(:disabled) {
      cursor: pointer;
      background-color: ${({ theme }) => theme.palette.purple.main};
      opacity: 1;
      color: white;

      :hover {
        background-color: ${({ theme }) => theme.palette.purple.dark};
      }

      :active {
        background-color: white;
        color: black;
      }
    }
  }

  div.modal {
    background-color: white;
    padding: ${pr(16)} ${pr(32)};
    border-radius: ${pr(5)};
  }
`;

export interface IContentProps {
  onClose: () => void;
}

interface IProps {
  open?: boolean;
  onClose: () => void;
  content: (props: IContentProps) => React.ReactChild;
}

export const Modal: React.FC<IProps> = ({
  open,
  onClose: handleClose,
  content,
}) => {
  const canClose = React.useRef(false);

  const handleMouseDown = React.useCallback(() => {
    canClose.current = true;
  }, []);

  const handleMouseUp = React.useCallback(() => {
    if (canClose.current) {
      handleClose();
    }
    canClose.current = false;
  }, [handleClose]);

  const handleClick = React.useCallback(() => {
    if (canClose.current) {
      handleClose();
    }
  }, [handleClose]);

  if (!open) return null;

  return (
    <Container
      onMouseUp={handleMouseUp}
      onMouseDown={handleMouseDown}
      onClick={handleClick}
    >
      <div
        className="modal"
        onClick={(e) => e.stopPropagation()}
        onMouseDown={(e) => e.stopPropagation()}
      >
        {content({ onClose: handleClose })}
      </div>
    </Container>
  );
};
