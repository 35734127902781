import "styled-components";

interface ITheme {
  palette: {
    background: string;
    purple: {
      main: string;
      dark: string;
    };
  };
}

declare module "styled-components" {
  export interface DefaultTheme extends ITheme {}
}

export const DefaultTheme: ITheme = {
  palette: {
    background: "#111",
    purple: { main: "#5700CA", dark: "#2C2560" },
  },
};

export const pr = (value: number) => `${value / 16}rem`;
