import * as React from "react";
import {
  noTombTaxStateDefault,
  useNoTaxSwapState,
} from "../Hooks/useNoTaxSwapState";

export const NoTaxSwapContext = React.createContext<
  ReturnType<typeof useNoTaxSwapState>
>({
  state: { ...noTombTaxStateDefault },
  deposit: () => Promise.reject(),
  closeNotification: () => {},
  closePending: () => {},
  closeSuccessfulTx: () => {},
  swap: () => Promise.reject(),
  withdraw: () => Promise.reject(),
});

export function useNoTaxSwapContext() {
  return React.useContext(NoTaxSwapContext);
}
