import * as React from "react";
import QRCode from "react-qr-code";
import styled from "styled-components";
import { DepositModal } from "../Components/Modals/DepositModal";
import { useWeb3React } from "../Hooks/useWeb3React";
import { useNoTaxSwapContext } from "../Contexts/NoTaxSwapContext";
import { Modal } from "../Components/Modals/Modal";
import { NotifyModal } from "../Components/Modals/NotifyModal";
import { LoadingModal } from "../Components/Modals/LoadingModal";
import { TransactionSuccess } from "../Components/Modals/TransactionSuccess";
import { utils } from "ethers";
import { pr } from "../AppTheme";

const Container = styled.div`
  button {
    background-color: ${({ theme }) => theme.palette.purple.dark};
    opacity: 0.5;
    /* border-radius: 1000000000px; */
    border: none;
    box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.6);
    padding: ${pr(2)} ${pr(16)} ${pr(4)};
    font-size: ${pr(18)};
    font-weight: bold;
    color: #999;

    :not(:disabled) {
      cursor: pointer;
      background-color: ${({ theme }) => theme.palette.purple.main};
      opacity: 1;
      color: white;

      :hover {
        background-color: ${({ theme }) => theme.palette.purple.dark};
      }

      :active {
        background-color: white;
        color: black;
      }
    }
  }

  div.para {
    @media (max-width: 959px) {
      padding: ${pr(24)} 0 ${pr(48)};
    }

    p {
      font-size: ${pr(21)};
      text-align: center;
      margin: 80px 0 40px;
      font-weight: 600;

      @media (max-width: 959px) {
        padding: 0 ${pr(16)};
        margin-top: ${pr(32)};
        margin-bottom: 0;
        font-size: ${pr(16)};
      }
    }
  }

  div.tool {
    display: flex;
    margin: ${pr(40)} auto;
    max-width: ${pr(1400)};

    @media (max-width: 959px) {
      margin-top: 0;
      flex-direction: column;
    }

    div.connect {
      font-weight: bold;
      font-size: ${pr(24)};
      margin: ${pr(18)} 0;
    }

    div.step-container {
      flex-basis: 50%;
      flex-grow: 1;
      min-width: 0;
      display: flex;
      padding: ${pr(36)};

      @media (max-width: 959px) {
        padding: ${pr(24)} ${pr(16)};
      }

      div.step-wrapper {
        border-radius: ${pr(20)};
        background-color: ${({ theme }) => theme.palette.purple.main};
        padding: ${pr(12)};
        box-shadow: 1px 1px 10px 0px rgba(255, 255, 255, 0.2);
        transition: box-shadow 0.1s ease-out;
        flex-grow: 1;
        display: flex;
        flex-direction: column;

        :hover {
          box-shadow: 1px 1px 10px 0px rgba(255, 255, 255, 0.35);
        }

        h2 {
          padding-bottom: ${pr(8)};
          font-size: ${pr(44)};
          text-align: center;
          margin: ${pr(10)} 0;

          @media (max-width: 959px) {
            font-size: ${pr(24)};
          }
        }

        div.step {
          border-radius: ${pr(15)};
          background-color: rgba(255, 255, 255, 0.85);
          padding: ${pr(30)};
          box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.15);
          flex-grow: 1;
        }

        div.step-1 {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          div.buttons {
            display: flex;
            width: 100%;
            justify-content: center;
            padding-top: ${pr(30)};

            @media (max-width: 959px) {
              flex-direction: column;
              padding-top: 0;
            }

            button {
              min-width: 25%;
              box-sizing: content-box;
              margin: 0 ${pr(10)};

              @media (max-width: 959px) {
                margin-top: ${pr(14)};
              }
            }
          }

          p {
            color: black;
            text-align: center;
            margin: ${pr(8)} 0;
            font-size: ${pr(18)};
            font-weight: 600;

            @media (max-width: 959px) {
              font-size: ${pr(16)};
            }

            span.can-be-withdrawn {
              font-style: italic;
              font-weight: normal;
            }
          }

          div.deposit-address {
            padding-top: ${pr(20)};
            padding-bottom: ${pr(10)};

            p {
              font-weight: bold;

              span {
                font-size: ${pr(24)};
                word-break: break-all;

                @media (max-width: 959px) {
                  display: block;
                  margin-top: ${pr(12)};
                  font-size: ${pr(18)};
                }
              }
            }
          }

          div.address-code {
            border: 2px solid black;

            svg {
              border: 2px solid white;
              display: block;
            }
          }
        }

        div.step-2 {
          display: flex;
          flex-direction: column;
          justify-content: center;

          input {
            font-size: ${pr(21)};
            color: black;
          }

          div.slippage-container {
            display: flex;
            justify-content: flex-end;

            input {
              font-size: inherit;
              width: ${pr(54)};
              margin-left: ${pr(8)};
            }
          }

          div.swap-amount {
            div.deposit-bal {
              font-weight: bold;
              display: flex;
              justify-content: space-between;
              padding-bottom: ${pr(6)};
              padding-top: ${pr(6)};
              cursor: pointer;
            }

            input {
              box-sizing: border-box;
              width: 100%;
            }
          }

          button.swap-btn {
            margin: ${pr(24)} ${pr(32)} 0;
          }

          div.to-toggle {
            display: flex;
            justify-content: flex-end;
            padding: ${pr(4)} 0;
            user-select: none;

            > div {
              font-weight: 600;
              cursor: pointer;

              &.open {
                font-weight: bold;
              }
            }
          }

          div.to {
            font-weight: bold;
            padding-bottom: ${pr(6)};
          }

          div.weth {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding: ${pr(4)} 0;

            > * {
              cursor: pointer;
            }

            input {
              margin: ${pr(3)} ${pr(8)} 0 0;
            }

            span {
              font-weight: 600;
              user-select: none;
            }
          }
        }
      }
    }
  }

  div.stats {
    border-top: 2px solid white;
    border-bottom: 2px solid white;
    display: flex;

    @media (max-width: 959px) {
      flex-direction: column;
    }

    > div {
      flex-basis: 50%;
      max-width: none;
      min-width: 0;
    }

    div.recent-swaps {
      border-right: 1px solid white;
      padding: ${pr(24)} ${pr(24)} 0 ${pr(24)};

      @media (max-width: 959px) {
        border-right: none;
        border-bottom: 2px solid white;
      }

      h3 {
        font-size: ${pr(24)};
        margin: 0 0 ${pr(12)};
        text-align: center;
      }

      div.links-wrapper {
        height: ${pr(150)};
        overflow-x: hidden;
        overflow-y: auto;
        margin: 0 ${pr(-24)};

        div.links {
          margin: auto;
          width: fit-content;
          max-width: 100%;
          box-sizing: border-box;

          @media (max-width: 959px) {
            padding: 0 ${pr(12)};
          }

          a {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            display: block;
            color: white;
          }
        }
      }
    }

    div.total-volume {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-left: 1px solid white;

      @media (max-width: 959px) {
        border-left: none;
        padding: ${pr(18)} 0 ${pr(12)};
      }

      h3 {
        font-size: ${pr(24)};
        margin: 0;
      }

      h4 {
        font-size: ${pr(72)};
        margin: 0;

        @media (max-width: 959px) {
          font-size: ${pr(44)};
        }
      }
    }
  }

  div.questions {
    padding: ${pr(24)} ${pr(24)} ${pr(80)};

    h2 {
      font-size: ${pr(32)};
      margin-top: 0;
    }

    h3 {
      font-size: ${pr(24)};
    }

    p {
      font-size: ${pr(18)};
      max-width: 60%;

      @media (max-width: 959px) {
        max-width: none;
      }
    }

    h4 {
      font-size: ${pr(18)};
    }
  }
`;

export const Home: React.FC = () => {
  const {
    state,
    closeNotification: handleNotificationClose,
    closePending: handleClosePending,
    closeSuccessfulTx: handleSuccessfulTxClose,
    swap,
  } = useNoTaxSwapContext();
  const {
    depositAddress,
    notification,
    notificationTitle,
    pendingOpen,
    successfulTx,
    depositedBal,
    swapTxs,
    volume,
  } = state;
  const { account } = useWeb3React();
  const [depositModalOpen, setDepositModalOpen] = React.useState(false);
  const [amount, setAmount] = React.useState("");
  const [weth, setWeth] = React.useState(false);
  const [to, setTo] = React.useState("");
  const [showTo, setShowTo] = React.useState(false);
  const [pending, setPending] = React.useState(false);
  const [slippage, setSlippage] = React.useState("0.5");
  const [isWithdraw, setIsWithdraw] = React.useState(false);

  const handleMakeDeposit = React.useCallback(() => {
    setIsWithdraw(false);
    setDepositModalOpen(true);
  }, []);

  const handleWithdraw = React.useCallback(() => {
    setIsWithdraw(true);
    setDepositModalOpen(true);
  }, []);

  const handleCloseDepositModal = React.useCallback(() => {
    setDepositModalOpen(false);
  }, []);

  const handleMax = React.useCallback(() => {
    if (depositedBal) {
      setAmount(utils.formatEther(depositedBal));
    }
  }, [depositedBal]);

  const handleSwap = React.useCallback(async () => {
    if (account) {
      setPending(true);
      await swap(
        utils.parseEther(amount),
        weth,
        showTo && to ? utils.getAddress(to) : account,
        Number(slippage)
      );
      setPending(false);
    }
  }, [account, amount, showTo, slippage, swap, to, weth]);

  const handleChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!e.target.value) {
        setAmount("");
      } else {
        try {
          utils.parseEther(e.target.value);
          setAmount(e.target.value);
        } catch {}
      }
    },
    []
  );

  const handleChangeSlippage = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSlippage(
        e.target.value
          ? (Math.round(Number(e.target.value) * 1000) / 1000).toString()
          : ""
      );
    },
    []
  );

  const handleToggleRecipient = React.useCallback(() => {
    setShowTo((showTo) => !showTo);
  }, []);

  const handleChangeTo = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setTo(e.target.value);
    },
    []
  );

  const handleChangeWeth = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setWeth(e.target.checked);
    },
    []
  );

  const handleToggleWeth = React.useCallback(() => {
    setWeth((weth) => !weth);
  }, []);

  const disableSwap =
    !account ||
    !amount ||
    utils.parseEther(amount).lte(0) ||
    pending ||
    (depositedBal && utils.parseEther(amount).gt(depositedBal)) ||
    isNaN(Number(slippage)) ||
    Number(slippage) <= 0;

  return (
    <>
      <Modal
        open={!!account && depositModalOpen}
        onClose={handleCloseDepositModal}
        content={(props) => <DepositModal {...props} isWithdraw={isWithdraw} />}
      />
      <Modal
        open={pendingOpen}
        onClose={handleClosePending}
        content={(props) => (
          <LoadingModal {...props} text="Pending Transaction" />
        )}
      />
      <Modal
        open={successfulTx !== ""}
        onClose={handleSuccessfulTxClose}
        content={(props) => (
          <TransactionSuccess
            {...props}
            para={notification}
            tx={successfulTx}
          />
        )}
      />
      <Modal
        open={notification !== ""}
        onClose={handleNotificationClose}
        content={(props) => (
          <NotifyModal
            {...props}
            para={notification}
            title={notificationTitle}
          />
        )}
      />
      <Container>
        <div className="para">
          <p>
            This site allows you to sell TOMB without any tax! There will be a
            fee to do so but it is much smaller than the actual gatekeeper tax.
          </p>
        </div>
        <div className="tool">
          <div className="step-container">
            <div className="step-wrapper">
              <h2>1 - Deposit TOMB</h2>
              <div className="step step-1">
                <p>
                  Send TOMB to your account's deposit contract at any time.
                  <br />
                  <span className="can-be-withdrawn">
                    Can be withdrawn without swapping
                  </span>
                </p>
                {account && depositAddress ? (
                  <>
                    <div className="deposit-address">
                      <p>
                        Deposit address for your account (
                        {account.substring(0, 6)}...
                        {account.substring(account.length - 4)}):
                        <br />
                        <span>{depositAddress}</span>
                      </p>
                    </div>
                    <div className="address-code">
                      <QRCode value={`ethereum:${depositAddress}`} />
                    </div>
                  </>
                ) : (
                  <div className="connect">Connect your wallet</div>
                )}
                <div className="buttons">
                  <button onClick={handleMakeDeposit} disabled={!account}>
                    Make Deposit
                  </button>
                  <button onClick={handleWithdraw} disabled={!account}>
                    Withdraw
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="step-container">
            <div className="step-wrapper">
              <h2>2 - Swap for FTM</h2>
              <div className="step step-2">
                <div className="slippage-container">
                  <div className="slippage">Slippage (%):</div>
                  <input
                    value={slippage}
                    onChange={handleChangeSlippage}
                    type="number"
                    min={0}
                    max={100}
                  />
                </div>
                <div className="swap-amount">
                  <div className="deposit-bal" onClick={handleMax}>
                    <div>TOMB Deposited: </div>
                    <div>
                      {depositedBal ? utils.formatEther(depositedBal) : "-"}
                    </div>
                  </div>
                  <input value={amount} onChange={handleChange} />
                </div>
                <div className={`to-toggle ${showTo ? "open" : ""}`}>
                  <div onClick={handleToggleRecipient}>
                    {showTo ? "-" : "+"} Add recipient
                  </div>
                </div>
                {showTo && (
                  <>
                    <div className="to">Recipient</div>
                    <input value={to} onChange={handleChangeTo} />
                  </>
                )}
                <div className="weth">
                  <input
                    type="checkbox"
                    checked={weth}
                    onChange={handleChangeWeth}
                  />
                  <span onClick={handleToggleWeth}>Swap for WFTM</span>
                </div>
                <button
                  onClick={handleSwap}
                  disabled={disableSwap}
                  className="swap-btn"
                >
                  {pending
                    ? "Pending"
                    : utils.isAddress(to) || !showTo
                    ? `Swap
                      ${
                        disableSwap
                          ? ""
                          : ` ${
                              amount.endsWith(".")
                                ? amount.substr(0, amount.length - 1)
                                : amount
                            } TOMB`
                      }`
                    : "Invalid recipient"}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="stats">
          <div className="recent-swaps">
            <h3>Recent Swaps (24h)</h3>
            <div className="links-wrapper">
              <div className="links">
                {swapTxs.map((tx) => (
                  <a
                    key={`tx-${tx}`}
                    href={`https://ftmscan.com/tx/${tx}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://ftmscan.com/tx/{tx}
                  </a>
                ))}
              </div>
            </div>
          </div>
          <div className="total-volume">
            <h3>Total Volume</h3>
            <h4>
              {!!volume
                ? Number(
                    utils.formatEther(volume).split(".")[0]
                  ).toLocaleString()
                : "-"}{" "}
              FTM
            </h4>
          </div>
        </div>

        <div className="questions">
          <h2>Questions</h2>
          <h3>What?</h3>
          <p>
            This site allows you to sell TOMB without any tax! There will be a
            fee to do so but it is much smaller than the actual gatekeeper tax.
          </p>
          <h3>Is this safe?</h3>
          <p>
            This website is perfectly safe, each account has their own deposit
            address which can be accessed only by them through our main{" "}
            <a
              href="https://ftmscan.com/address/0xC1C6caCb78466a555b11dA0Df6D0BB07a1Afb708#code"
              target="_blank"
              rel="noreferrer"
            >
              contract
            </a>
            . The contract is verifed and you are welcome to have a read and
            make sure you're happy its safe. You will notice that when executing
            a swap the contract sends the TOMB to an unverified contract, but it
            immediately checks that an equivelent amount of WFTM has been
            returned, and will revert the transaction if this doesn't happen so
            you can be sure your funds are not being stolen!
          </p>
          <h4>
            If you have any questions or concerns please reach out to us on
            Twitter, or join our Discord.
          </h4>
        </div>
      </Container>
    </>
  );
};
